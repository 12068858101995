import axios from 'axios'
import env from 'settings/env'

const API_PROFILE_URL = env.API_URL + '/profile'
const API_IP_URL = env.API_URL + '/event/ipaddress'

export async function getProfileUser(token: string) {
  const options = {
    method: 'GET',
    url: API_PROFILE_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const response = await axios.request(options)
  return response
}

export async function getUserIp() {
  const options = {
    method: 'GET',
    url: API_IP_URL,
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const response = await axios.request(options)
  return response.data.body
}
