import routes from 'routes'
import { v4 as uuidv4 } from 'uuid'

import ReactPixel from 'react-facebook-pixel'

export function initializePixel(themeId: string) {
  ReactPixel.init(themeId)
}

export function pixelPageView(location) {
  ReactPixel.pageView()
}

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    fbq: any
  }
}

export function sendPixelEvent(
  name: string,
  id: string,
  clickId?: string | null
) {
  if (window.fbq === undefined) {
    return
  }
  switch (name) {
    case 'InitiateCheckout':
      // ReactPixel.track('InitiateCheckout', {
      //   eventID: id,
      //   client_ip_address: client_ip_address,
      //   fbc: clickId
      // })
      window.fbq(
        'track',
        'InitiateCheckout',
        { fbc: clickId },
        { event_id: id }
      )
      break
    case 'ChildrenRegister':
      // ReactPixel.trackCustom('ChildrenRegister', {
      //   eventID: id,
      //   client_ip_address: client_ip_address,
      //   fbc: clickId
      // })
      window.fbq(
        'trackCustom',
        'ChildrenRegister',
        { fbc: clickId },
        { event_id: id }
      )
      break
    case 'UserRegister':
      // ReactPixel.trackCustom('UserRegister', {
      //   eventID: id,
      //   client_ip_address: client_ip_address,
      //   fbc: clickId
      // })
      window.fbq(
        'trackCustom',
        'UserRegister',
        { fbc: clickId },
        { event_id: id }
      )
      break
    case 'AddressRegister':
      // ReactPixel.trackCustom('AddressRegister', {
      //   eventID: id,
      //   client_ip_address: client_ip_address,
      //   fbc: clickId
      // })
      window.fbq(
        'trackCustom',
        'AddressRegister',
        { fbc: clickId },
        { event_id: id }
      )
      break
    case 'AddPaymentInfo':
      // ReactPixel.track('AddPaymentInfo', {
      //   eventID: id,
      //   client_ip_address: client_ip_address,
      //   fbc: clickId
      // })
      window.fbq('track', 'AddPaymentInfo', { fbc: clickId }, { event_id: id })
      break
    default:
      ReactPixel.pageView()
      break
  }
}

export function sendPixelEventPurchase(id: string, value: number) {
  // ReactPixel.fbq('track', 'Purchase', {
  //   eventID: id,
  //   currency: 'BRL',
  //   value: value
  // })
  window.fbq(
    'track',
    'Purchase',
    {
      currency: 'BRL',
      value: value
    },
    { event_id: id }
  )
}

export function facebookConversionData(themeId: string, total: number) {
  // ReactPixel.fbq('track', 'Purchase', {
  //   eventID: `${uuidv4()}`,
  //   currency: 'BRL',
  //   value: total
  // })
  window.fbq(
    'track',
    'Purchase',
    {
      currency: 'BRL',
      value: total
    },
    { event_id: `${uuidv4()}` }
  )
}

export function getFbc(clickId: string) {
  // version.subdomainIndex.creationTime.<fbclid>
  const version = 'fb'
  const subdomainIndex = 1
  const creationTime = new Date().getTime()
  const fbclid = clickId

  return `${version}.${subdomainIndex}.${creationTime}.${fbclid}`
}

export function getFbp() {
  // version.subdomainIndex.creationTime.<fbclid>
  const version = 'fb'
  const subdomainIndex = 1
  const creationTime = new Date().getTime()
  const random = String(new Date().getTime()).slice(0, 10)

  return `${version}.${subdomainIndex}.${creationTime}.${random}`
}
