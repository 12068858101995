import axios from 'axios'
import {
  getFbc,
  getFbp,
  sendPixelEvent,
  sendPixelEventPurchase
} from 'services/events/pixel'
import env from 'settings/env'
import { v4 as uuidv4 } from 'uuid'

const API_CRM_URL = env.API_URL

export async function sendOzyEvent(
  page: string,
  email?: string,
  token?: string | null
): Promise<any> {
  const options = {
    method: 'POST',
    url: API_CRM_URL + `/event/pageview`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      page: page,
      email: email
    }
  }
  if (token) {
    options.headers['Authorization'] = `Bearer ${token}`
  }

  const response = await axios.request(options)
  return response.data.body
}

export async function sendOzyPageViewEvent(
  page: string,
  token?: string | null
): Promise<any> {
  const options = {
    method: 'POST',
    url: API_CRM_URL + `/event/pageview`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      page: page
    }
  }

  if (token) {
    options.headers['Authorization'] = `Bearer ${token}`
  }

  const response = await axios.request(options)
  return response.data.body
}

export type EventType = {
  eventId: string
  eventName: string
  fbc?: string
  fbp?: string
  subscriptionId?: string | number
  token?: string
  value?: number
}

export async function sendOzyEventNew(
  data: EventType,
  token?: string | null
): Promise<any> {
  const options = {
    method: 'POST',
    url: API_CRM_URL + `/event`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      ...data
    }
  }

  if (token) {
    options.headers['Authorization'] = `Bearer ${token}`
  }

  const response = await axios.request(options)
  return response.data.body
}

export type OzyPixelType = {
  eventName: string
  clickId?: string | null
  token?: string | null
  subscriptionId?: string | number | null
  value?: number
  client_ip_address?: string
}

export async function sendOzyAndPixelEvents({
  eventName,
  clickId,
  token,
  subscriptionId,
  value = 0,
  client_ip_address
}: OzyPixelType) {
  const eventId = uuidv4()

  const data: EventType = {
    eventId,
    eventName
  }

  if (clickId && typeof clickId === 'string') {
    data.fbc = getFbc(clickId)
    data.fbp = getFbp()
  }

  if (subscriptionId) {
    data.subscriptionId = subscriptionId
  }

  if (eventName === 'Purchase') {
    data.value = value
    sendPixelEventPurchase(eventId, value)
  } else {
    sendPixelEvent(eventName, eventId, clickId)
  }

  await sendOzyEventNew(data, token)

  return true
}
