import { combineReducers } from '@reduxjs/toolkit'
import address from 'store/modules/Address.store'
import ambassador from 'store/modules/Ambassador.store'
import additionalItems from 'store/modules/AdditionalItems.store'
import crmEvents from 'store/modules/CrmEvents.store'
import footer from 'store/modules/Footer.store'
import kids from 'store/modules/Kids.store'
import payment from 'store/modules/Payment.store'
import discount from 'store/modules/Discount.store'
import plans from 'store/modules/Plans.store'
import steps from 'store/modules/Steps.store'
import theme from 'store/modules/Theme.store'
import toast from 'store/modules/Toast.store'
import urlParams from 'store/modules/UrlParams.store'
import logs from 'store/modules/Log.store'

const rootReducer = combineReducers({
  address,
  ambassador,
  additionalItems,
  crmEvents,
  footer,
  kids,
  payment,
  discount,
  plans,
  steps,
  theme,
  toast,
  urlParams,
  logs
})

export default rootReducer
