import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import {
  BodyObjectPlans,
  PlansAvailableType,
  PlansCardDetails,
  PlansInfo,
  PlansType
} from 'types'
import { formatedPlans } from 'utils/plans/helpers'

export type PlansState = {
  plans: BodyObjectPlans
  plansCardBox: PlansCardDetails[]
  plansAvailable: PlansAvailableType[]
  planSelected: PlansInfo
  planSelectedDetails: PlansType
  isOrderBump: boolean
}

const initialState: PlansState = {
  plans: {},
  plansCardBox: [],
  plansAvailable: [],
  planSelected: {
    period: '',
    plan: ''
  },
  planSelectedDetails: {
    active: false,
    monthly_price: 0,
    name: '',
    periodicity: 0,
    published: false,
    shipping_price: 0,
    product_amount: '1',
    slug: '',
    type: '',
    additional_items: []
  },
  isOrderBump: false
}

const plans = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    setPlans: (state, action: PayloadAction<BodyObjectPlans>) => {
      state.plans = action.payload
    },
    setPlansCardBox: (state, action: PayloadAction<PlansCardDetails[]>) => {
      state.plansCardBox = action.payload
    },
    setPlansAvailable: (state, action: PayloadAction<PlansAvailableType[]>) => {
      state.plansAvailable = action.payload
    },
    setPlanSelected: (state, action: PayloadAction<PlansInfo>) => {
      state.planSelected = action.payload
    },
    setPlanSelectedWithPlan: (
      state,
      action: PayloadAction<{ planSelected: PlansInfo; plans: BodyObjectPlans }>
    ) => {
      const planSelected = action.payload.planSelected
      const plans = action.payload.plans

      if (plans) {
        const plansAvailable = formatedPlans(plans)?.filter(
          (item) => item.period.toString() === planSelected.period
        )

        if (plansAvailable) {
          state.plansAvailable = plansAvailable
        }
      }

      state.planSelected = planSelected
    },
    setPlanSelectedDetails: (state, action: PayloadAction<PlansType>) => {
      if (action.payload !== null) {
        state.planSelectedDetails = action.payload
      }
    },
    setIsOrderBump: (state, action: PayloadAction<boolean>) => {
      state.isOrderBump = action.payload
    },
    reset: () => initialState
  }
})

export const {
  setPlans,
  setPlansCardBox,
  setPlansAvailable,
  setPlanSelected,
  setPlanSelectedWithPlan,
  setPlanSelectedDetails,
  setIsOrderBump,
  reset
} = plans.actions

export const selectPlans = (state: RootState) => state.plans

export default plans.reducer
